import React from "react"
import styled from "styled-components"

const StyledMap = styled.iframe`
  margin: 24px 0;
  width: 100%;
  height: 450px;
  border: none;
`

const GoogleMaps = ({ address }) => {
  return (
    <StyledMap
      src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDPpAu6jgtFtm5lZreDl9orTGLvpfbiL30
    &q=${address}`}
      allowfullscreen
    ></StyledMap>
  )
}

export default GoogleMaps
